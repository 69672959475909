
















































































import { Component, Vue, Ref } from 'vue-property-decorator';
import { agentModule } from '@/store/modules/agent';
import { ValidationObserver } from 'vee-validate';
@Component
export default class SendEmail extends Vue {
    @Ref() observer!: InstanceType<typeof ValidationObserver>;
    form = {
        email: ''
    };

    async onSubmit() {
        try {
            await agentModule.sendPasswordReset(this.form.email);
            this.form.email = '';
            this.observer.reset();
            this.$notify.success({
                title: 'Se envio el correo con éxito',
                message: 'La operación fue exitosa'
            });
        } catch (error) {
            this.$notify.error({
                title: 'Error',
                message:
                    typeof error.mensaje === 'string'
                        ? error.mensaje
                        : 'Hemos tenido algunos inconvenientes'
            });
        }
    }
}
